export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/about": [3],
		"/algebra_1_2_difference": [4],
		"/blog/accomplishments_of_openai": [5],
		"/blog/evolution": [6],
		"/blog/future_of_education": [7],
		"/blog/notable_ai": [8],
		"/blog/pedagogical_unsoundness/chemistry": [9],
		"/blog/poincare": [10],
		"/blog/problems_with_modern_vc_interest": [11],
		"/blog/sound_instructional_computer_science": [12],
		"/blog/why_calculus": [13],
		"/blog/why_learn": [14],
		"/blog/why_us": [15],
		"/calculus_careers_styled": [17],
		"/calculus_careers": [16],
		"/compare": [18],
		"/demo": [19],
		"/demo/instructors/old": [20],
		"/demo/instructors/old/old2": [21],
		"/faqs": [22],
		"/ifaqs": [23],
		"/legal/terms_of_service": [24],
		"/mission": [25],
		"/mission/gb": [26],
		"/mission/spooky": [27],
		"/mission/students": [28],
		"/play": [29],
		"/privacy_policy": [30],
		"/scheduling-thanks": [31],
		"/scroll_demo": [32],
		"/survey/intro": [33]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';